<template>
    <div>
      <base-page-title :titleText="pageTitle" />
      <b-row class="no-gutters p-3">
        
      </b-row>
      <b-col class="col-12 col-md-8 p-3 mx-auto">
        <subscriptions-list />
      </b-col>
    </div>
  </template>
  
  <script>
  import SubscriptionsList from '../components/SubscriptionsPredict/SubscriptionsList.vue';
  export default {
    components: {
      SubscriptionsList
    },
    data() {
      return {
        pageTitle: "page.subscriptions.title"
      };
    },
    methods: {
      
    }
  };
  </script>
  
  <style scoped></style>
  